import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import Faq from '../Faq';
import Stories from './sections/Stories';
import Medications from './sections/Medications';
import MultiSteps from './sections/MutiSteps';
const WeightLossQB = () => {
  const { slug } = useParams();
  const utm_campaign = 'qb';
  const utm_referral = slug ?? '';

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let changed = false;
  if (!searchParams.get('utm_campaign')) {
    searchParams.set('utm_campaign', utm_campaign);
    changed = true;
  }
  if (!searchParams.get('utm_referral') && utm_referral) {
    searchParams.set('utm_referral', utm_referral);
    changed = true;
  }
  if (changed) {
    window.location.search = searchParams;
  }
  const discount = 5;

  return (
    <>
      <Helmet>
        <title>UrgentCare.com: Weight Loss For You.</title>
      </Helmet>

      <div className="weightloss-hero ahs">
        <div className="cta">
          <div className="box">
            <h1>Lose Weight with GLP-1 Medications</h1>
          </div>
        </div>
      </div>
      <Medications discount={discount} showBrandedMeds={true} />
      <div className="weightloss-outlets">
        <h3>GLP-1 medications have been covered by...</h3>
        <div className="outlet-logos">
          <img src="/img/outlets-1.png" className="align-self-center outlet1" alt="..." />
          <img src="/img/outlets-2.png" className="align-self-center outlet2" alt="..." />
        </div>
      </div>
      <MultiSteps />
      <div className="weightloss-trusted">
        <hr className="divider" />
        <img className="hero" src="/img/couple-fistbump.png" alt="Enjoy Convenient Delivery" />
        <h3>
          Trusted Medications Prescribed by Caring Providers. Conveniently Delivered to Your Door.
        </h3>
        <p>
          Experience top-notch telemedicine assistance customized to your specific health needs. Our
          trusted partners at Ola Health and their expert clinicians ensures you have continual
          access to the most effective treatments, prioritizing your well being.
        </p>
        <a href="#start" className="btn orange">
          See If I Qualify
        </a>
      </div>
      <Stories />
      <Faq id="faq" />
    </>
  );
};

export default WeightLossQB;
