import React from 'react';
import { Helmet } from 'react-helmet';

class Partnership extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            UrgentCare.com: Find Your Local Urgent Care Center, Get Directions, Hours of Operation
            and COVID-19 Testing - Partnership
          </title>
        </Helmet>
        <div className="section bg-2">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5">
                <h1 className="text-center">Partner With Us</h1>
                <p className="text-center">Let's Work Together</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-5 offset-md-1">
                <img
                  src="/img/partner-with-us-img.png"
                  alt="urgent-care"
                  className="img-fluid mb-4 mb-md-0"
                />
              </div>
              <div className="col-md-6 order-md-first ">
                <p className="lead-gray mb-4">
                  Behind UrgentCare.com and its related websites, AntibodyTesting.com and
                  PharmacyNearMe.com, are knowledgeable and creative marketers and doctors.{' '}
                </p>

                <p className="mb-4">
                  Our marketers leverage their years of experience in the healthcare industry to
                  direct patients to your urgent care, with the goal of increasing visibility,
                  traffic and workflow. Our marketing team has scaled businesses in the past to
                  reach more than 15 million households and is accustomed to partnering with and
                  working on behalf of large healthcare companies.{' '}
                </p>

                <p className="mb-4">
                  Our doctors oversee all medical information to ensure the most up-to-date and
                  accurate medical information is communicated to the public (your potential
                  patients) and work to foster excellent relationships with urgent cares across the
                  country.
                </p>

                <p className="mb-4">
                  If you’re interested in partnering with us to get your Urgent Care Center more
                  exposure to the public and within the medical industry, we have some options for
                  you.
                </p>

                <div className="media align-items-center">
                  <img className="mr-3" src="/img/ico/mail-icon.svg" alt=" " />
                  <div className="media-body">
                    Send us an email (
                    <a href="mailto:partner@urgentcare.com" className="mail">
                      partner@urgentcare.com
                    </a>
                    ) if you’d like to learn more and we’ll set up a time to talk.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section"
          style={{
            backgroundImage: "url('/img/partnership-bg.png')",
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col mb-3 mb-md-5">
                <h2 className="text-white">Partnership</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="box round-border-10 drop-shadow p-3 p-md-5">
                  <p className="p3">Partnership</p>
                  <h4>Tier One</h4>
                  <ul className="my-3 my-md-5 circ-ul">
                    <li>
                      Featured Listing on UrgentCare.com for consumers within 10 miles radius of
                      your location{' '}
                    </li>
                    <li>Featured tweets on our Twitter page: Twitter.com/UrgentCare</li>
                    <li>Dedicated PPC campaigns for individual centers that we manage</li>
                    <li>
                      Inclusion in our quarterly Hello Newsletter to the primary care physicians
                      within a 10 mile radius of your center
                    </li>
                    <li>Supply chain liaison for antibody and COVID testing </li>
                    <li>Inclusion on AntibodyTesting.com as a Covid and Antibody testing site</li>
                    <li>Featured tweets on our Twitter page: Twitter.com/AntibodyTesting</li>
                  </ul>
                  <a href="mailto:partner@urgentcare.com" className="learn-more">
                    I'm Interested
                  </a>
                </div>
              </div>
              <div className="col-md-6 mt-4 mt-md-0">
                <div className="box round-border-10 drop-shadow p-3 p-md-5 h-100">
                  <p className="p3">Partnership</p>
                  <h4>Tier Two</h4>
                  <ul className="my-3 my-md-5 circ-ul">
                    <li>
                      Featured Listing on UrgentCare.com for consumers within 10 miles radius of
                      your location{' '}
                    </li>
                    {/* <li>Dedicated PPC campaigns for individual centers that we will manage </li> */}
                    <li>Supply chain liaison for antibody and COVID testing </li>
                    <li>Inclusion on AntibodyTesting.com as a Covid and Antibody testing site </li>
                  </ul>
                  <a href="mailto:partner@urgentcare.com" className="learn-more always-bottom">
                    I'm Interested
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Partnership;
