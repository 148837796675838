import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Faq from '../Faq';
import { buildWellsyncIframeUrl } from '../../Helpers';
import { handleIframeMessage } from '../../Client';
import { useLocation } from 'react-router-dom';
import BackLink from './components/BackLink';

const displayPrice = {
  semaglutideodt: 199,
  tirzepatideodt: 299,
  semaglutide: 209,
  tirzepatide: 309,
  mounjaro: 1399,
  zepbound: 1399,
  rybelsus: 1299,
  ozempic: 1299,
};

const GetStartedZepbound = () => {
  const discount = 0;

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Get Started with Zepbound</title>
      </Helmet>
      <div className="container">
        <div className="outgoing-banner">
          <BackLink />
          <div className="outgoing-banner-content">
            <img src="/img/meds/glp1-zepbound-display.png" alt="Zepbound" />

            <p>
              Zepbound
              <br />
              {discount > 0 && (
                <small className="price-reduction">
                  ${displayPrice.zepbound} /month <span className="red-bar"></span>
                </small>
              )}
              <strong>${displayPrice.zepbound - discount} /month</strong>
              <small className="info">
                Includes <br className="d-block d-md-none" />
                Provider + Medication
                <br />
                No Hidden Fees – No Insurance Needed
                <br />
              </small>
            </p>
          </div>
        </div>
      </div>
      <iframe
        src={buildWellsyncIframeUrl('zepbound')}
        title="Get Started with Zepbound"
        className="full-page"
      />

      <Faq id="faq" />
    </>
  );
};

export default GetStartedZepbound;
