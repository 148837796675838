import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Faq from '../Faq';

const WeightLossForWomen = () => {
  return (
    <>
      <Helmet>
        <title>UrgentCare.com: Weight Loss For Women</title>
      </Helmet>
      <div className="weightloss-hero">
        <div className="cta">
          <div className="box">
            <h1>Lose Weight with GLP-1 Medications</h1>
          </div>
        </div>
      </div>
      <div className="weightloss-vials">
        <div className="cta">
          <div className="box">
            <h3>Weight Loss Simplified</h3>
            <p>Get started today with the weight loss treatment that works for you.</p>
            <div className="pricing">
              <div className="half left">
                <div className="copy">
                  <img src="/img/glp1-sema-vial.png" alt="Compounded Semaglutide $209" />

                  <p>
                    Semaglutide <strong>$209 /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                    </small>
                    <Link href="#" className="btn orange">
                      Get Started
                    </Link>
                  </p>
                </div>
              </div>

              <div className="half right">
                <div className="copy">
                  <img src="/img/glp1-tirz-vial.png" alt="Compounded Tirzepatide $309" />
                  <p>
                    Tirzepatide <strong>$309 /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                    </small>
                    <Link href="#" className="btn orange">
                      Get Started
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="prices"></div>
      </div>

      <div className="weightloss-outlets">
        <h3>GLP-1s medications have been covered by...</h3>
        <div className="outlet-logos">
          <img src="/img/outlets-1.png" className="align-self-center outlet1" alt="..." />
          <img src="/img/outlets-2.png" className="align-self-center outlet2" alt="..." />
        </div>
      </div>

      <div className="weightloss-steps">
        <h3>Take These Three Steps Toward the New You!</h3>

        <div className="list-steps">
          <div className="step">
            <img
              className="hero"
              src="/img/steps-clipboard-icon.png"
              alt="Complete Intake Questionnaire"
            />
            <h4>
              <img src="/img/steps-one.png" className="step-img" alt="Step One" />
              Complete Intake Questionnaire
            </h4>
            <p>Your doctor will use this information to prepare for your consultation.</p>
          </div>

          <div className="step bordered">
            <img
              className="hero"
              src="/img/steps-drconv-icon.png"
              alt="Consult with Your Provider."
            />
            <h4>
              <img src="/img/steps-two.png" className="step-img" alt="Step Two" />
              Consult with Your Provider
            </h4>
            <p>
              Your licensed weight loss provider will check to determine what treatment is right for
              you.
            </p>
          </div>

          <div className="step">
            <img
              className="hero"
              src="/img/steps-shiptime-icon.png"
              alt="Enjoy Convenient Delivery"
            />
            <h4>
              <img src="/img/steps-three.png" className="step-img" alt="Step Three" />
              Enjoy Convenient Delivery
            </h4>
            <p>Your prescription will arrive at your doorstep every month in discrete packaging.</p>
          </div>
        </div>
      </div>

      <div className="weightloss-trusted">
        <hr className="divider" />
        <img className="hero" src="/img/couple-fistbump.png" alt="Enjoy Convenient Delivery" />
        <h3>
          Trusted Medications Prescribed by Caring Providers. Conveniently Delivered to Your Door.
        </h3>

        <p>
          Experience top-notch telemedicine assistance customized to your specific health needs. Our
          team of expert clinicians ensures you have ready access to the most effective treatments,
          prioritizing your well being.
        </p>
        <Link href="#" className="btn orange">
          See If I Qualify
        </Link>
      </div>

      <div className="weightloss-stories">
        <hr className="divider" />
        <h3>Success Stories</h3>

        <div className="list-stories">
          <div className="story">
            <img
              className="hero"
              src="/img/mom-baby-pool.jpeg"
              alt="Complete Intake Questionnaire"
            />
            <h4>Dress to Impress: A Journey to the Perfect Fit</h4>
            <p>
              With her wedding looming, Emma faced a dress that wouldn't zip. She chose
              transformation over compromise, dedicating herself to health. On the big day, the
              dress fit not just her body but her journey of resilience, making her shine from
              within.
            </p>
          </div>

          <div className="story bordered">
            <img className="hero" src="/img/mom-son-yoga.jpeg" alt="Consult with Your Doctor." />
            <h4>Dress to Impress: A Journey to the Perfect Fit</h4>
            <p>
              With her wedding looming, Emma faced a dress that wouldn't zip. She chose
              transformation over compromise, dedicating herself to health. On the big day, the
              dress fit not just her body but her journey of resilience, making her shine from
              within.
            </p>
          </div>

          <div className="story">
            <img
              className="hero"
              src="/img/mom_daughter_dance.jpeg"
              alt="Enjoy Convenient Delivery"
            />
            <h4>Dress to Impress: A Journey to the Perfect Fit</h4>
            <p>
              With her wedding looming, Emma faced a dress that wouldn't zip. She chose
              transformation over compromise, dedicating herself to health. On the big day, the
              dress fit not just her body but her journey of resilience, making her shine from
              within.
            </p>
          </div>
        </div>
      </div>

      <Faq />
    </>
  );
};

export default WeightLossForWomen;
