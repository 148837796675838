import React, { useState, useEffect } from 'react';

const MultiSteps = () => {
  const [expandedSteps, setExpandedSteps] = useState({});
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // Initialize all steps as expanded on desktop
  useEffect(() => {
    const handleResize = () => {
      const desktop = window.innerWidth > 768;
      setIsDesktop(desktop);

      // If desktop, expand all steps
      if (desktop) {
        const allExpanded = steps.reduce(
          (acc, step) => ({
            ...acc,
            [step.id]: true,
          }),
          {}
        );
        setExpandedSteps(allExpanded);
      }
    };

    // Initial setup
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleStep = (stepId) => {
    // Only allow toggling on mobile
    if (!isDesktop) {
      setExpandedSteps((prev) => ({
        ...prev,
        [stepId]: !prev[stepId],
      }));
    }
  };

  const steps = [
    {
      id: 'step1',
      title: 'Step 1: Select Medication',
      content: (
        <>
          <p>Compound Options: Semaglutide or Tirzepatide (injectable or dissolving tablet)</p>
          <p>
            Brand Options: Zepbound, Mounjaro, Ozempic, or Rybelsus (injectable pen or oral tablet)
          </p>
          <p>
            Compound medications offer the same active ingredients at a lower cost than brand-name
            drugs.
          </p>
        </>
      ),
    },
    {
      id: 'step2',
      title: 'Step 2: Complete Questionnaire',
      content: (
        <>
          <p>
            The online questionnaire has 18 questions and will be reviewed by a physician. If you
            live in AR, DC, DE, ID, KS, LA, MS, NM, or WV, a telemedicine appointment with Ola
            Digital Health is required.
          </p>
          <p>
            The prescriber may recommend a different medication or ingestion method based on your
            medical review.
          </p>
        </>
      ),
    },
    {
      id: 'step3',
      title: 'Step 3: Submit Payment',
      content: (
        <p>
          Once the questionnaire is completed, you will then submit the monthly payment for the
          prescription you initially selected.
        </p>
      ),
    },
    {
      id: 'step4',
      title: 'Step 4: Physician Review',
      content: (
        <p>
          The medical provider will then review the responses to the questionnaire and determine
          whether a telemedicine appointment is necessary or whether there is sufficient evidence to
          write the script for the preselected medication.
        </p>
      ),
    },
    {
      id: 'step5',
      title: 'Step 5: Physician Decision',
      content: (
        <p>
          IF PRESCRIPTION IS APPROVED - Your prescription for a one-month supply will be sent to the
          pharmacy partner based on their availability in stock. IF PRESCRIPTION IS NOT APPROVED -
          The entire amount paid by you will be refunded if the medical provider does not ultimately
          write a prescription.
        </p>
      ),
    },
    {
      id: 'step6',
      title: 'Step 6: Shipment',
      content: (
        <>
          <p>
            The medication will be shipped directly to your home address and you will receive
            shipping notification directly from the pharmacy.
          </p>
          <p>The package will include:</p>
          <ul>
            <li>Alcohol swabs (if injectable medication is selected)</li>
            <li>Syringes (if injectable medication is selected)</li>
            <li>Instructions for use and care</li>
          </ul>
          <p>
            *Injectable medications are shipped in cold packaging to maintain proper storage
            temperature.
          </p>
        </>
      ),
    },
    {
      id: 'step7',
      title: 'Step 7: Refill Process',
      content: (
        <p>
          Your payment method will be charged 28 days after your last payment for the next month's
          supply.
          <br />
          You must complete a 12-question refill questionnaire (sent via email from
          Support@WellSync.com) for your prescription to be fulfilled.
        </p>
      ),
    },
    {
      id: 'step8',
      title: 'Step 8: Refill Decision',
      content: (
        <p>
          Based on your responses:
          <ul>
            <li>
              A medical provider approves the refill and you'll receive email confirmation, OR:
            </li>
            <li>Refill will be denied and you'll receive a full refund</li>
          </ul>
          This process will repeat monthly until you no longer require the medication.
        </p>
      ),
    },
    {
      id: 'support',
      title: 'Customer Support',
      content: (
        <p>
          Consumers with questions need to email Support@WellSync.com. On average WellSync responds
          by email within 15 minutes during business hours. To cancel after payment, email
          Support@WellSync.com for a refund.
        </p>
      ),
    },
  ];

  return (
    <div className="multi-steps">
      {steps.map(({ id, title, content }) => (
        <div key={id} className="multi-steps-container">
          <h2 onClick={() => toggleStep(id)} className="step-header">
            {title}
            {!isDesktop && (
              <span className="toggle-indicator">{expandedSteps[id] ? '−' : '+'}</span>
            )}
          </h2>
          {(isDesktop || expandedSteps[id]) && content}
        </div>
      ))}
    </div>
  );
};

export default MultiSteps;
