import React from 'react';

function LearnMoreSection() {
  return (
    <div className="section" style={{ backgroundColor: 'rgba(246,248,250,2)' }}>
      <div className="container">
        <div className="row">
          <a
            className="col-md-6 mt-4"
            href="https://urgentcare.carehub.wellsync.com/conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="home-box round-border-10 p-40"
              style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
            >
              <img
                src="/img/ico2/Appointment.svg"
                alt="Book Your Doctor"
                className="mb-2 mb-md-5"
                height="145px"
                width="auto"
                style={{ padding: '2px', margin: '0px auto', display: 'block' }}
              />
              <h3 className="mb-0 text-center">Feeling Unwell?</h3>
              <p className="lead-violet mb-3 text-center">Complete our online questionnaire.</p>
              <h4 className="text-center pb-3 border-bottom">
                Get diagnosed and prescribed medication, no in-person appointment needed in most
                cases.
              </h4>
              <ul className="mb-5 half pl-5 md:pl-0">
                <li>Sinus Infection / Sinus Headache / Cold</li>
                <li>Urinary Tract Infection (UTI)</li>
                <li>Flu</li>
                <li>Covid-19</li>
              </ul>
              <ul className="mb-5 half md:border-left pl-5">
                <li>Pink Eye (Conjunctivitis)</li>
                <li>Skin Rashes</li>
                <li>Allergies</li>
                <li>Short-Term Medication Refill</li>
                <li>And More</li>
              </ul>
              <div className="home-box-footer">
                <button className="learn-more btn green">Get Care Instantly</button>
              </div>
            </div>
          </a>

          <a
            className="col-md-6 mt-4"
            href="/weight-loss-program"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="home-box round-border-10 p-40"
              style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
            >
              <img
                src="/img/ico2/GLP-1b.svg"
                alt="Lose weight with GLP-1"
                className="mb-3 mb-md-5"
                height="145px"
                width="auto"
                style={{ padding: '2px', margin: '0px auto', display: 'block' }}
              />
              <h3 className="mb-0 mt-0 text-center">Lose Weight With GLP&#x2011;1 Medications.</h3>
              <p className="lead-violet mb-3 text-center">Start Your Journey Today!</p>
              <p className="mb-5">
                Obesity is a disease that is often still associated with stigma, bias and
                misinformation. We are working to help you make informed decisions around obesity
                while making GLP-1 medications more accessible. Helping fight obesity one person at
                a time.
              </p>
              <div className="home-box-footer">
                <button className="learn-more btn green" href="/weight-loss-program">
                  Learn More
                </button>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default LearnMoreSection;
