import React from 'react';
import { buildUrlWithQueryParams } from '../../../Helpers';

const Medications = ({ discount, showBrandedMeds = false }) => {
  const displayPrice = {
    semaglutideodt: 199,
    tirzepatideodt: 299,
    semaglutide: 209,
    tirzepatide: 309,
    mounjaro: 1399,
    zepbound: 1399,
    rybelsus: 1299,
    ozempic: 1299,
  };

  return (
    <>
      <div className="weightloss-vials odt">
        <div className="cta">
          <div className="box">
            <h3 id="start" className="start">
              Weight Loss Simplified
            </h3>
            <p>Get started today with the weight loss treatment that works for you.</p>

            <div className="pricing">
              <div className="half left">
                <div className="copy">
                  <img src="/img/meds/glp1-semavial.png" alt="Compounded Semaglutide" />
                  <p>
                    <span className="small">
                      Injectable
                      <br />
                    </span>{' '}
                    Semaglutide
                    <br />
                    {discount > 0 && (
                      <small className="price-reduction">
                        ${displayPrice.semaglutide} /month <span className="red-bar"></span>
                      </small>
                    )}
                    <strong>${displayPrice.semaglutide - discount} /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                      <br />
                    </small>
                    <a
                      href={buildUrlWithQueryParams('/weight-loss-program/get-started-semaglutide')}
                      className="btn orange"
                    >
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
              <div className="half right">
                <div className="copy">
                  <img src="/img/meds/glp1-tirzvial.png" alt="Compounded Tirzepatide" />
                  <p>
                    {' '}
                    <span className="small">
                      Injectable
                      <br />
                    </span>{' '}
                    Tirzepatide
                    <br />
                    {discount > 0 && (
                      <small className="price-reduction">
                        ${displayPrice.tirzepatide} /month <span className="red-bar"></span>
                      </small>
                    )}
                    <strong>${displayPrice.tirzepatide - discount} /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                      <br />
                    </small>
                    <a
                      href={buildUrlWithQueryParams('/weight-loss-program/get-started-tirzepatide')}
                      className="btn orange"
                    >
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="weightloss-vials odt">
        <div className="cta">
          <div className="box">
            <div className="pricing ">
              <div className="half left">
                <div className="copy">
                  <img src="/img/meds/glp1-semapills-obt.png" alt="Compounded Semaglutide" />
                  <p>
                    <span className="small">
                      Orally Dissolving Tablets
                      <br />
                    </span>{' '}
                    Semaglutide
                    <br />
                    {discount > 0 && (
                      <small className="price-reduction">
                        ${displayPrice.semaglutideodt} /month <span className="red-bar"></span>
                      </small>
                    )}
                    <strong>${displayPrice.semaglutideodt - discount} /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                      <br />
                    </small>
                    <a
                      href={buildUrlWithQueryParams(
                        '/weight-loss-program/get-started-semaglutide-odt'
                      )}
                      className="btn orange"
                    >
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
              <div className="half right">
                <div className="copy">
                  <img src="/img/meds/glp1-tirzpills-obt.png" alt="Compounded Tirzepatide" />
                  <p>
                    <span className="small">
                      Orally Dissolving Tablets
                      <br />
                    </span>{' '}
                    Tirzepatide
                    <br />
                    {discount > 0 && (
                      <small className="price-reduction">
                        ${displayPrice.tirzepatideodt} /month <span className="red-bar"></span>
                      </small>
                    )}
                    <strong>${displayPrice.tirzepatideodt - discount} /month</strong>
                    <small className="info">
                      Includes Provider + Medication
                      <br />
                      No Hidden Fees – No Insurance Needed
                      <br />
                    </small>
                    <a
                      href={buildUrlWithQueryParams(
                        '/weight-loss-program/get-started-tirzepatide-odt'
                      )}
                      className="btn orange"
                    >
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showBrandedMeds && (
        <>
          <div className="weightloss-vials odt">
            <div className="cta">
              <div className="box">
                <div className="pricing ">
                  <div className="half left">
                    <div className="copy">
                      <img src="/img/meds/glp1-rybelsus-display.png" alt="Rybelsus" />
                      <p>
                        Rybelsus
                        <br />
                        <strong>${displayPrice.rybelsus.toLocaleString()} /month</strong>
                        <small className="info">
                          Includes Provider + Medication
                          <br />
                          No Hidden Fees – No Insurance Needed
                          <br />
                        </small>
                        <a
                          href={buildUrlWithQueryParams(
                            '/weight-loss-program/get-started-rybelsus'
                          )}
                          className="btn orange"
                        >
                          Get Started
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="half right">
                    <div className="copy">
                      <img src="/img/meds/glp1-zepbound-display.png" alt="Zepbound Tirzepatide" />
                      <p>
                        Zepbound
                        <br />
                        <strong>${displayPrice.zepbound.toLocaleString()} /month</strong>
                        <small className="info">
                          Includes Provider + Medication
                          <br />
                          No Hidden Fees – No Insurance Needed
                          <br />
                        </small>
                        <a
                          href={buildUrlWithQueryParams(
                            '/weight-loss-program/get-started-zepbound'
                          )}
                          className="btn orange"
                        >
                          Get Started
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="weightloss-vials odt">
            <div className="cta">
              <div className="box">
                <div className="pricing">
                  <div className="half left">
                    <div className="copy">
                      <img src="/img/meds/glp1-ozempic-display.png" alt="Ozempic Tirzepatide" />
                      <p>
                        Ozempic
                        <br />
                        <strong>${displayPrice.ozempic.toLocaleString()} /month</strong>
                        <small className="info">
                          Includes Provider + Medication
                          <br />
                          No Hidden Fees – No Insurance Needed
                          <br />
                        </small>
                        <a
                          href={buildUrlWithQueryParams('/weight-loss-program/get-started-ozempic')}
                          className="btn orange"
                        >
                          Get Started
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="half right">
                    <div className="copy">
                      <img src="/img/meds/glp1-mounjaro-display.png" alt="Mounjaro Semaglutide" />
                      <p>
                        Mounjaro
                        <br />
                        <strong>${displayPrice.mounjaro.toLocaleString()} /month</strong>
                        <small className="info">
                          Includes Provider + Medication
                          <br />
                          No Hidden Fees – No Insurance Needed
                          <br />
                        </small>
                        <a
                          href={buildUrlWithQueryParams(
                            '/weight-loss-program/get-started-mounjaro'
                          )}
                          className="btn orange"
                        >
                          Get Started
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br className="clear block h-10 mb-20" />
          </div>
        </>
      )}
    </>
  );
};

export default Medications;
