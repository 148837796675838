import React from 'react';

function Partnership() {
  return (
    <div className="section partner-with-us">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 offset-md-1 relative">
            <img src="/img/phone-doc.png" alt="urgent-care" className="phone-doc" />
          </div>
          <div className="col-md-5 order-md-first text-center">
            <h2 className="mb-3">Need Common Medical Treatment Quickly?</h2>
            <p>Get care at home without an appointment in most cases. $35</p>
            <a
              href="https://urgentcare.carehub.wellsync.com/conditions"
              className="btn green my-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Care Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partnership;
