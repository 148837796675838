import React from 'react';
import { useHistory } from 'react-router-dom';
import { buildUrlWithQueryParams } from '../../../Helpers';
function BackLink() {
  const history = useHistory();

  const handleGoBack = (event) => {
    event.preventDefault();
    history.goBack();
  };

  return (
    <a
      href={buildUrlWithQueryParams('/weight-loss-program')}
      onClick={handleGoBack}
      className="return-link"
    >
      Back
    </a>
  );
}

export default BackLink;
