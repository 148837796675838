import React from 'react';
import { Link } from 'react-router-dom';

class Subscription extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <div class="section sub py-0">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-4  col-xl-4">
                <div
                  class="p-3 p-md-3 my-lg-5 my-0 rounded"
                  style={{ backgroundColor: 'rgba(246,248,250,2)' }}
                >
                  <h2 class="mb-3 mt-md-5">
                    Monthly Health
                    <br />
                    E-Newsletter
                  </h2>
                  <p>
                    Subscribe to our monthly health e-newsletter, where we will send out updates on
                    important issues like the flu vaccine, COVID-19 Viral and advancements in
                    antibody testing, as well as new information and notices from the CDC and the
                    World Health Organization and other medical institutions and scientific studies
                    regarding medical issues that are important to you.
                  </p>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 offset-lg-2 p-2  offset-xl-3 col-xl-5">
                <div class="sub-box my-md-5 mb-4 p-3 p-md-5 p-xl-4">
                  <img
                    src="/img/ico/mobile-icon.svg"
                    alt="mobile"
                    class="float-left  img-fluid  mr-3"
                  />
                  <h4>
                    Subscribe to Our
                    <br />
                    Monthly Health E-Newsletter
                  </h4>

                  <hr class="my-4 my-md-5" />
                  <div class="mx-0 mx-md-5 mx-xl-4">
                    <form>
                      <div class="form-group">
                        <label for="subEmail">Email Address</label>
                        <input
                          type="email"
                          class="form-control drop-shadow"
                          id="subEmail"
                          placeholder=""
                        />
                      </div>
                    </form>
                    <Link to="">
                      <a href="!#" class="btn-cta btn-block text-center my-4">
                        Subscribe
                      </a>
                    </Link>

                    <p class="p2">
                      By providing your email address and clicking "Subscribe" you agree to
                      receiving our periodic e-newsletter at the email address provided. You may
                      unsubscribe at any time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Subscription;
