import React from 'react';

function AboutUsSection() {
  return (
    <div className="section who-are-we">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 no-gutters">
            <div className="box-1">
              <h4 className=" text-white">Who Are We?</h4>
              <div className="separator-line"></div>
              <p className="text-white">
                Welcome to Urgentcare.com - we are glad you are here! We strive to be a trusted
                resource for you and your loved ones when you need medical advice and care.{' '}
              </p>

              <p className="text-white">
                Urgentcare.com has gathered a comprehensive list of more than 14,000 Urgent Care
                centers across the nation, as well as telemedicine services, to meet your medical
                needs.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-1 my-5 my-md-0">
            <div
              className="box p-50 round-border-10"
              style={{ backgroundColor: 'rgba(75,76,103,1.1)', border: 'none' }}
            >
              <img src="/img/ico/heart-plus.svg" className="align-self-center mr-3" alt="..." />
              <h4 className=" text-white">How Can An Urgent Care Help?</h4>

              <p className="text-white">
                From minor illnesses to injuries, Urgentcare.com’s providers can help you with many
                ailments.{' '}
              </p>

              <p className="text-white mb-4">
                If you think you’re experiencing a serious medical emergency, please dial 911
                immediately.{' '}
              </p>
              {/*<a className="read-more text-white" href="#">Read More  <i className="fas fa-arrow-right ml-3"></i></a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsSection;
