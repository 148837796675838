import React from 'react';
import { Helmet } from 'react-helmet';
import Faq from '../Faq';
import Stories from './sections/Stories';
import Medications from './sections/Medications';
import { useLocation } from 'react-router-dom';

const WeightLoss = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const discount = searchParams.get('utm_campaign') ? 5 : 0;

  return (
    <>
      <Helmet>
        <title>UrgentCare.com: Weight Loss For You.</title>
      </Helmet>
      <div className="weightloss-hero">
        <div className="cta">
          <div className="box">
            <h1>Lose Weight with GLP-1 Medications</h1>
          </div>
        </div>
      </div>
      <Medications discount={discount} showBrandedMeds={false} />
      <div className="weightloss-outlets">
        <h3>GLP-1 medications have been covered by...</h3>
        <div className="outlet-logos">
          <img src="/img/outlets-1.png" className="align-self-center outlet1" alt="..." />
          <img src="/img/outlets-2.png" className="align-self-center outlet2" alt="..." />
        </div>
      </div>
      <div className="weightloss-steps">
        <h3>Take These Three Steps Toward the New You!</h3>
        <div className="list-steps">
          <div className="step">
            <img
              className="hero"
              src="/img/steps-clipboard-icon.png"
              alt="Complete Intake Questionnaire"
            />
            <h4>
              <img src="/img/steps-one.png" className="step-img" alt="Step One" />
              Complete Intake Questionnaire
            </h4>
            <p>Your provider will use this information to prepare for your consultation.</p>
          </div>
          <div className="step bordered">
            <img
              className="hero"
              src="/img/steps-drconv-icon.png"
              alt="Consult with Your Provider."
            />
            <h4>
              <img src="/img/steps-two.png" className="step-img" alt="Step Two" />
              Consult with Your Provider
            </h4>
            <p>
              Your licensed weight loss provider will check to determine what treatment is right for
              you.
            </p>
          </div>
          <div className="step">
            <img
              className="hero"
              src="/img/steps-shiptime-icon.png"
              alt="Enjoy Convenient Delivery"
            />
            <h4>
              <img src="/img/steps-three.png" className="step-img" alt="Step Three" />
              Enjoy Convenient Delivery
            </h4>
            <p>Your prescription will arrive at your doorstep every month in discrete packaging.</p>
          </div>
        </div>
      </div>
      <div className="weightloss-trusted">
        <hr className="divider" />
        <img className="hero" src="/img/couple-fistbump.png" alt="Enjoy Convenient Delivery" />
        <h3>
          Trusted Medications Prescribed by Caring Providers. Conveniently Delivered to Your Door.
        </h3>
        <p>
          Experience top-notch telemedicine assistance customized to your specific health needs. Our
          team of expert clinicians ensures you have continual access to the most effective
          treatments, prioritizing your well being.
        </p>
        <a href="#start" className="btn orange">
          See If I Qualify
        </a>
      </div>
      <Stories />
      <Faq id="faq" />
    </>
  );
};

export default WeightLoss;
