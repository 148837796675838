import React from 'react';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>
          UrgentCare.com: Find Your Local Urgent Care Center, Get Directions, Hours of Operation and
          COVID-19 Testing - About Us
        </title>
      </Helmet>
      <div className="section bg-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">About Us</h1>
              <p className="text-center">
                Thanks for stopping by. We hope we are helpful and you feel better soon.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section who-are-we">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 no-gutters">
              <div className="box-1">
                <h4 className=" text-white">Who Are We?</h4>
                <div className="separator-line"></div>
                <p className="text-white">
                  Welcome to Urgentcare.com - we are glad you are here! We strive to be a trusted
                  resource for you and your loved ones when you need medical advice and care.{' '}
                </p>

                <p className="text-white">
                  Urgentcare.com has gathered a comprehensive list of more than 14,000 Urgent Care
                  centers across the nation, as well as telemedicine services, to meet your medical
                  needs.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-5 offset-md-1 my-5 my-md-0">
              <div
                className="box p-50 round-border-10"
                style={{
                  backgroundColor: 'rgb(75, 76, 103)',
                  border: 'none',
                }}
              >
                <img src="/img/ico/heart-plus.svg" className="align-self-center mr-3" alt="..." />
                <h4 className=" text-white">How Can An Urgent Care Help?</h4>

                <p className="text-white">
                  From minor illnesses to injuries, Urgentcare.com’s providers can help you with
                  many ailments.{' '}
                </p>

                <p className="text-white mb-4">
                  If you think you’re experiencing a serious medical emergency, please dial 911
                  immediately.{' '}
                </p>
                {/*<a className="read-more text-white" href="#">Read More <i*/}
                {/*    className="fas fa-arrow-right ml-3"></i></a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="text-center">Our Team</h3>
              <div
                className="separator-line mx-auto mt-3"
                style={{
                  backgroundColor: 'rgb(255, 74, 48)',
                }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-4">
              <div className="box round-border-10 p-md-4 p-3 h-100">
                <div className="media">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="mb-2">Sean Sullivan</h4>
                      <p className="text-purple">Co-Founder and Advisory Board Member</p>
                      <p className="p2">
                        Sean Sullivan is the Founder and former CEO of Health Network Group.
                        HealthNetwork is one of the most successful self-funded healthcare marketing
                        companies in the United States. Launched in 2014 it quickly grew to become
                        one of the largest channels for customer acquisition for a number of
                        insurance carriers and public MGA’s. It was acquired in 2018 in a private
                        transaction and is now owned by a Fortune 100 insurance carrier. Sean is
                        also the founder of CyberInsurance.com, a leading informational property
                        focused on cyber liability coverage for the P&C industry. He enjoys spending
                        time with his wife and children.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4 mb-md-4">
              <div className="box round-border-10 p-md-4 p-3 h-100">
                <div className="media">
                  <div className="row">
                    <div className="col-12">
                      <h4 className="mb-2">Erika Sullivan</h4>
                      <p className="text-purple">Co-Founder and Advisory Board Member</p>
                      <div>
                        <p className="p2">
                          Erika Sullivan, Esq. is an attorney licensed to practice in Florida and
                          New Jersey. In 2014, Erika and her husband, Sean, started Health Network
                          Group, a consumer-centric health insurance marketing company that, at its
                          peak, helped 18 million people research and gain access to healthcare.
                          Having proper health insurance coverage is vital, but equally as important
                          is having access to excellent medical care, which is why UrgentCare.com
                          was created to introduce patients and urgent care providers across the
                          nation. In her free time Erika enjoys spending quality time with her
                          husband and twins.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <img src="/img/about-us-img.png" alt="about us" className="img-fluid rounded-10" />
            </div>
            <div className="col-12">
              <hr
                style={{
                  backgroundColor: 'rgb(255, 132, 33)',
                }}
                className="my-5"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p className="lead-violet mb-5">
                <strong>We respect</strong> that your time is valuable!
                <br />
                <strong>We strive</strong> to keep our databases up to date to ensure you have the{' '}
                <strong>best experience</strong> possible.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>
                In part because of complications from COVID-19 clinic hours can vary, we strongly
                advise you to call prior to travelling to confirm access and availability.
              </p>

              <p>
                Our urgent care professionals can help in diagnosis and treatment of conditions that
                include but are not limited to COVID-19, colds, influenza (the flu), strep throat,
                pink eye, ear infections, allergies, UTIs, STDs, headaches, constipation,
                lacerations, back pain, strained muscles and non-emergent broken bones.
              </p>

              <p>
                In many cases, treatment can be provided during the consultation with the licensed
                medical professional, or during a telemedicine visit. In other situations it may be
                necessary for our providers to refer you for additional diagnostic testing,
                specialist consultation or hospital care.
              </p>
            </div>

            <div className="col-md-6">
              <p>
                We believe that knowledge is power. We aim to be a trusted source for medical
                knowledge here at Urgentcare.com. This website has additional information and
                resources regarding ailments affecting different parts of the body and
                recommendations of when to seek medical attention.
              </p>

              <p>
                We will update relevant information from trusted resources on various conditions
                including the latest on COVID-19 as it becomes available.
              </p>

              <p>
                Your health, and the health of your loved ones, is our utmost priority. Thank you
                for trusting us here at urgentcare.com. Have a great day!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
