import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Stories = () => {
  const [expandedStories, setExpandedStories] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [currentStory, setCurrentStory] = useState(null);

  const toggleStory = (index) => {
    setExpandedStories((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !prevStates[index];
      return newStates;
    });
  };

  const openModal = (story) => {
    setCurrentStory(story);
  };

  const closeModal = () => {
    setCurrentStory(null);
  };

  const stories = [
    {
      imgSrc: '/img/oldman-golf.png',
      alt: 'Unexpected Benefit, Arthritis Is Gone',
      title: 'Unexpected Benefit, Arthritis Is Gone',
      content:
        "At 67 years old, I’d tried countless ways to shed the extra 40 pounds that I’ve carried since high school. Starting the journey my primary goal was to lose the weight that had lingered for decades. In just eight months, I hit my target. But the biggest, most welcome change wasn't just seeing the number on the scale drop. It was an incredible improvement in my arthritis. For years, I’d been woken up by the pain, but now, I sleep through the night without discomfort. The relief in my joints has been life-changing and, quite honestly, my favorite part of this journey. Compounded Tirzepatide didn’t just help me reach my weight loss goals—it brought me unexpected relief and improved my quality of life.",
      author: 'Jeffrey Thomas - St Petersburg, FL',
      index: 0,
    },
    {
      imgSrc: '/img/happy-scale-woman.png',
      alt: 'Transforming My Health with Compounded Semaglutide',
      title: 'Transforming My Health with Compounded Semaglutide',
      content:
        "As a 40-year-old my main reason for starting compounded Semaglutide was to lose 30 pounds. I wanted to feel lighter and healthier, and so far, I’ve seen steady progress on that goal. The most surprising benefit has been the improvements in my cholesterol and blood pressure. For the first time in my adult life, both are in the best range they’ve ever been. The changes to my blood pressure and cholesterol have been a revelation, giving me not just weight loss results but also long-term health benefits I hadn't anticipated. Compounded Semaglutide has truly exceeded my expectations.",
      author: 'Jennifer Moreno - Ashville, NC',
      index: 1,
    },
    {
      imgSrc: '/img/man-tea.png',
      alt: 'Compounded Semaglutide’s Impact on Achalasia Symptoms',
      title: 'Compounded Semaglutide’s Impact on Achalasia Symptoms',
      content:
        "I decided to try compounded Semaglutide to lose 40 pounds and I’m definitely seeing steady progress on that goal. I didn’t expect the impact it would have on my achalasia—a condition that makes swallowing difficult and eating impossible at times. Since starting the medication my achalasia symptoms have disappeared entirely. For the first time in over twenty years I can eat without fearing that I'll have complications during a meal. Compounded Semaglutide has given me more than weight loss. I wish I had been aware of this years ago.",
      author: 'Sean Casey - State College, PA',
      index: 2,
    },
  ];

  const altStories = [
    {
      imgSrc: '/img/man-on-a-mission.png',
      alt: 'Celebrating Milestones with Tirzepatide: Breaking the 200-Pound Mark',
      title: 'Celebrating Milestones with Tirzepatide: Breaking the 200-Pound Mark',
      content:
        'This morning, I hit a huge milestone, weighing in at 199.8 lbs—barely under 200, but definitely a number worth celebrating! I started taking tirzepatide in late January and am now in my first month at the 10 mg dose. Progress has been steady, with a consistent 15-20 lbs lost per dose before reaching a plateau. I switched to 10 mg when I reached 205 lbs, and I’m hopeful to get below 190 before moving up to 12.5 mg to reach my goal weight, then tapering down to a maintenance dose. Starting this journey at 257 lbs, I’m now 6’0” and 36 years old, weighing in just shy of 200 lbs for the first time in years. My ultimate goal is 175 lbs.',
      author: 'John Robbins - Detroit, MI',
      index: 3,
    },
    {
      imgSrc: '/img/yoga-dancer.png',
      alt: 'My First Month on Compounded Semaglutide: Early Progress and Lessons Learned',
      title: 'My First Month on Semaglutide: Early Progress and Lessons Learned',
      content:
        'I wanted to share my experience from my first month on treatment! I’m 32, 5\'4", and started at 202 lbs, with a current weight of 197 lbs and a goal of 140-150 lbs. I took my first 2.5 mg dose on August 4th and quickly noticed, as others have, that the “food noise” was gone. During the first couple of weeks, I found myself having to make an effort to eat, and I learned that going too long without food or drinking enough water could lead to headaches. For the first two weeks, I kept my diet quite restrictive, but I’ve relaxed a bit while house-sitting for a family member. I’m not eating poorly, but I’m being mindful with portion sizes, especially with higher-calorie foods like pizza. I’ve noticed my appetite gradually returning, so I’m planning to increase my dosage starting next week. Excited to see where this journey goes! I’ve been afraid to weigh myself but I was happy to find that I’ve lost 5 lbs since I started earlier this month!',
      author: 'Susan Shepperly - Pennsacola, FL',
      index: 4,
    },
    {
      imgSrc: '/img/couple-baby.png',
      alt: 'Five Weeks In: Early Wins and Lasting Changes on My Weight Loss Journey',
      title: 'Five Weeks In: Early Wins and Lasting Changes on My Weight Loss Journey',
      content:
        'I started this journey five weeks ago, and so far, I’m down a total of 10.2 lbs. While I’m mindful not to focus solely on the scale because of normal fluctuations, it’s still encouraging to see that progress. Beyond the numbers, I’m noticing real benefits—I feel better, my inflammation has decreased, and that constant "food noise" is now basically silenced. I can see small changes in my body, too, which has been motivating. I spent my first month on 2.5 mg, then moved up to 5 mg, where I’ll likely stay for a while. Some days, eating can be challenging, so I’m focused on getting at least 100g of protein daily to maintain good nutrition. Due to life’s demands, I haven’t started working out yet, but I plan to slowly incorporate exercise into my routine this week. Looking back, I only wish I’d started this journey sooner!',
      author: 'Todd Reynolds - Duluth, GA',
      index: 5,
    },
  ];

  return (
    <>
      <Helmet>
        <style>{`
          @media screen and (min-width: 701px) {
            .storymodal {
              display: ${currentStory ? 'block' : 'none'};
            }
          }
        `}</style>
      </Helmet>
      <div className="weightloss-stories">
        <hr className="divider" />
        <h3>Success Stories</h3>
        <div className="list-stories">
          {stories.map((story, index) => (
            <div key={story.index} className={`story ${index !== 1 ? '' : 'bordered'}`}>
              <img className="hero" src={story.imgSrc} alt={story.alt} />
              <h4>{story.title}</h4>
              <div className={`story-scroll ${expandedStories[story.index] ? 'readmore' : ''}`}>
                {story.content}
              </div>
              <p>
                {!expandedStories[story.index] && (
                  <button
                    className="showreadmore"
                    onClick={() =>
                      window.innerWidth > 700 ? openModal(story) : toggleStory(story.index)
                    }
                  >
                    Read more
                  </button>
                )}
                {expandedStories[story.index] && (
                  <button className="showreadmore" onClick={() => toggleStory(story.index)}>
                    Show less
                  </button>
                )}
                <small className="author">{story.author}</small>
              </p>
            </div>
          ))}
        </div>
        <div className="list-stories alt">
          {altStories.map((story, index) => (
            <div key={story.index} className={`story ${index !== 1 ? '' : 'bordered'}`}>
              <img className="hero" src={story.imgSrc} alt={story.alt} />
              <h4>{story.title}</h4>
              <div className={`story-scroll ${expandedStories[story.index] ? 'readmore' : ''}`}>
                {story.content}
              </div>
              <p>
                {!expandedStories[story.index] && (
                  <button
                    className="showreadmore"
                    onClick={() =>
                      window.innerWidth > 700 ? openModal(story) : toggleStory(story.index)
                    }
                  >
                    Read more
                  </button>
                )}
                {expandedStories[story.index] && (
                  <button className="showreadmore" onClick={() => toggleStory(story.index)}>
                    Show less
                  </button>
                )}
                <small className="author">{story.author}</small>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="storymodal">
        <div className="storymodal-content">
          <span className="close" onClick={closeModal}>
            ×
          </span>
          {currentStory && (
            <>
              <img src={currentStory.imgSrc} alt={currentStory.alt} />
              <h4>{currentStory.title}</h4>
              <p>{currentStory.content}</p>
              <small className="author">{currentStory.author}</small>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Stories;
